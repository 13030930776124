import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
import Delete from "@mui/icons-material/Delete";
import Build from "@mui/icons-material/Build";
import Assignment from "@mui/icons-material/Assignment";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AlertDialog from "components/AlertDialog/AlertDialog";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import Render from "Utils/RenderUtils";
import ActiviteTreeView from "./SubForms/ActiviteTreeView";
import DocumentTemplate from "components/Table/DocumentTemplate";
import Ged from "views/Tables/Ged";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import Esignatures from "views/Tables/Esignatures";
import AmoUtils from "Utils/AmoUtils";
import { withUserContext } from "context/UserContext";

class AmoView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.amo");
    this.CAN_DELETE = this.USER.can("delete.amo");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.CAN_USE_AMO = this.USER.can("use.amo");
    this.GED_AMO_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.portail.amo"),
      CAN_EDIT: this.USER.can("edit.ged.portail.amo"),
      CAN_DELETE: this.USER.can("delete.ged.portail.amo"),
    };
    this.CAN_VIEW_ESIGNATURE = this.USER.canOne([
      "view.esignature",
      "edit.esignature",
    ]);

    this.BACKEND_URL = "/amo";
    this.FRONT_URL = "/amo";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);
    this.ESIGNATURE_REF = React.createRef();

    this.state = {
      // NESTED DATA
      activites: [],
      logement: null,
      type_formule: null,
      // DATA FORM
      description: "",
      etat_missions: [],
      appliquer_tva: false,
      montant_tva: "",
      cout_total_missions_ht: "",
      cout_total_missions_ttc: "",
      montant_participation_anah: "",
      pourcentage_participation_anah: "",
      sous_traitance: false,
      // END DATA FORM
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          // const entities = err.response.data;
          this.onError();
        }
      });
  };

  onError = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer cet AMO."
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer l'AMO ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  render() {
    const {
      id,
      activites,
      logement,
      type_formule,
      description,
      etat_missions,
      appliquer_tva,
      montant_tva,
      cout_total_missions_ht,
      cout_total_missions_ttc,
      montant_participation_anah,
      pourcentage_participation_anah,
      sous_traitance,
    } = this.state;
    const { user } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  AMO
                </Typography>
              }
            />
            <CardContent>
              <HeaderTitle label="Bénéficiaire" />
              <Typography>
                <strong>Bénéficiaire : </strong>
                <Link
                  to={"/beneficiaires/detail/" + logement?.beneficiaire?.id}
                >
                  {logement?.beneficiaire?.prenom} {logement?.beneficiaire?.nom}
                </Link>
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>
                  <strong>Catégorie des ressources : </strong>
                  {logement?.beneficiaire?.categorie_ressource?.value}
                </Typography>
                <Box
                  sx={{
                    width: 25,
                    height: 25,
                    borderRadius: 1,
                    backgroundColor:
                      logement?.beneficiaire?.categorie_ressource?.color_mpr,
                  }}
                />
              </Stack>
              <HeaderTitle label="Logement" />
              <Typography>
                <strong>Logement : </strong>
                <Link to={"/logements/detail/" + logement?.id}>
                  {Render.fullAddress(logement?.adresse)}
                </Link>
              </Typography>
              <HeaderTitle label="AMO" />
              <Typography>
                <strong>Type de formule : </strong>
                {type_formule?.value}
              </Typography>
              <CheckBoxView label="Sous traitance" value={sous_traitance} />
              <CheckBoxView label="Appliquer la TVA" value={appliquer_tva} />
              <Typography>
                <strong>
                  Montant de la TVA{" "}
                  <InfoLightTooltip title={AmoUtils.MONTANT_TVA_TOOLTIP} /> :{" "}
                </strong>
                {Render.euro(montant_tva)}
              </Typography>
              <Typography>
                <strong>
                  Coût total des missions HT{" "}
                  <InfoLightTooltip title={AmoUtils.COUT_MISSION_HT_TOOLTIP} />{" "}
                  :{" "}
                </strong>
                {Render.euro(cout_total_missions_ht)}
              </Typography>
              <Typography>
                <strong>
                  Coût total des missions TTC{" "}
                  <InfoLightTooltip title={AmoUtils.COUT_MISSION_TTC_TOOLTIP} />{" "}
                  :{" "}
                </strong>
                {Render.euro(cout_total_missions_ttc)}
              </Typography>
              <Typography>
                <strong>
                  Pourcentage participation ANAH{" "}
                  <InfoLightTooltip
                    title={AmoUtils.POURCENTAGE_PARTICIPATION_ANAH_TOOLTIP}
                  />{" "}
                  :{" "}
                </strong>
                {Render.percent(pourcentage_participation_anah)}
              </Typography>
              <Typography>
                <strong>
                  Montant participation ANAH{" "}
                  <InfoLightTooltip
                    title={AmoUtils.MONTANT_PARTICIPATION_ANAH_TOOLTIP}
                  />{" "}
                  :{" "}
                </strong>
                {Render.euro(montant_participation_anah)}
              </Typography>
              <Typography>
                <strong>Description du projet : </strong>
                {description}
              </Typography>
            </CardContent>
          </Card>
          {this.CAN_USE_AMO && (
            <DocumentTemplate baseURL={this.FRONT_URL} objectId={id} />
          )}
          <Ged
            rootFolder="documents"
            section="portail"
            type="amo"
            objectId={this.state.id}
            permissions={this.GED_AMO_PERMISSIONS}
            frontUrl={this.FRONT_URL}
            signButton
            getSignataires={() => {
              const beneficiaire = this.state.logement?.beneficiaire;
              const user = this.props.user;

              return [
                {
                  prenom: user?.firstname,
                  nom: user?.lastname,
                  email: user?.email,
                },
                {
                  prenom: beneficiaire?.prenom,
                  nom: beneficiaire?.nom,
                  email: beneficiaire?.email,
                },
              ];
            }}
            onSignSent={() => this.ESIGNATURE_REF.current.loadAsyncData()}
          />
          {this.CAN_VIEW_ESIGNATURE && (
            <Esignatures
              ref={this.ESIGNATURE_REF}
              user={user}
              history={this.props.history}
              signable_type="App\Models\AMO"
              signable_id={id}
              section="portail"
              dense
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Assignment />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Missions et Activités
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  {activites.length > 0 ? (
                    <ActiviteTreeView
                      activites={activites}
                      etatMissions={etat_missions}
                    />
                  ) : (
                    <>Aucune donnée à afficher.</>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Amo&id=" + this.state.id}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

AmoView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AmoView);
