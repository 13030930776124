import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
import Delete from "@mui/icons-material/Delete";
import Person from "@mui/icons-material/Person";
import Home from "@mui/icons-material/Home";
import Euro from "@mui/icons-material/EuroSymbol";
import Add from "@mui/icons-material/Add";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Render from "Utils/RenderUtils";
import Ged from "views/Tables/Ged";
import { withUserContext } from "context/UserContext";

import BeneficiaireSubTable from "views/Forms/TravauxSubTables/BeneficiaireSubTable";

class BeneficiairesView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.beneficiaire");
    this.CAN_DELETE = this.USER.can("delete.beneficiaire");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.GED_BENEFICIAIRE_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.portail.beneficiaire"),
      CAN_EDIT: this.USER.can("edit.ged.portail.beneficiaire"),
      CAN_DELETE: this.USER.can("delete.ged.portail.beneficiaire"),
    };

    this.BACKEND_URL = "/beneficiaires";
    this.FRONT_URL = "/beneficiaires";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      logements: [],
      amos: [],
      structure_locale: null,
      civilite: null,
      type_representant: null,
      categorie_ressource: null,
      // DATA FORM
      nom: "",
      prenom: "",
      date_debut_mandat: "",
      date_fin_mandat: "",
      telephone: "",
      email: "",
      // END DATA FORM
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const entities = err.response.data;
          this.onError(entities);
        }
      });
  };

  onError = (entities) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce Bénéficiaire : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {entities.logements > 0 && (
              <li>{entities.logements} logement(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le Bénéficiaire ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  render() {
    const {
      structure_locale,
      logements,
      amos,
      civilite,
      type_representant,
      nom,
      prenom,
      date_debut_mandat,
      date_fin_mandat,
      telephone,
      email,
      categorie_ressource,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bénéficiaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Structure Locale : </strong>
                <Link to={"/structures-locales/detail/" + structure_locale?.id}>
                  {structure_locale?.nom}
                </Link>
              </Typography>
              <Typography>
                <strong>Civilité : </strong>
                {civilite?.value}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {nom}
              </Typography>
              <Typography>
                <strong>Prénom : </strong>
                {prenom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(telephone)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(email)}
              </Typography>
              <Typography>
                <strong>Date de début du mandat : </strong>
                {Render.date(date_debut_mandat)}
              </Typography>
              {type_representant?.value === "Syndic" && (
                <Typography>
                  <strong>Date de fin du mandat : </strong>
                  {Render.date(date_fin_mandat)}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar sx={{ bgcolor: "gla.main" }}>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Ressources
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Type de représentant : </strong>
                {type_representant?.value}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>
                  <strong>Catégorie des ressources : </strong>
                  {categorie_ressource?.value}
                </Typography>
                <Box
                  sx={{
                    width: 25,
                    height: 25,
                    borderRadius: 1,
                    backgroundColor: categorie_ressource?.color_mpr,
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logements
                </Typography>
              }
            />
            <CardContent align="right">
              <Grid container>
                <Grid item xs={12}>
                  <BeneficiaireSubTable
                    logements={logements}
                    dossiers_travaux={amos}
                    beneficiaire_id={this.state.id}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Link
                    to={"/logements/ajouter?beneficiaire_id=" + this.state.id}
                  >
                    <Button color="primary" size="small" sx={{ m: 1 }}>
                      <Add /> Ajouter un logement
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Ged
            rootFolder="documents"
            section="portail"
            type="beneficiaire"
            objectId={this.state.id}
            permissions={this.GED_BENEFICIAIRE_PERMISSIONS}
            frontUrl={this.FRONT_URL}
          />
        </Grid>
        <Grid item xs={12} lg={6} />
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL + "/logs?type=Beneficiaire&id=" + this.state.id
              }
            >
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

BeneficiairesView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(BeneficiairesView);
