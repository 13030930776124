import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material
import ManageAccounts from "@mui/icons-material/ManageAccounts";

// core components
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import SelectSearch from "components/CustomSelect/SelectSearch";
import TextInput from "components/CustomInput/TextInput";
import PhoneInput from "components/PhoneInput/PhoneInput";
import Render from "Utils/RenderUtils";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";

class AgentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      telephone: "",
      titre_id: "",
      first_name: "",
      last_name: "",
      email: "",
      civilite_id: "",
      signature_electronique_active: false,
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      backendUrl,
      configTitreUrl,
      structureId,
      canEdit,
      canDelete,
      canToggleEsign,
    } = this.props;
    const {
      id,
      titre_id,
      telephone,
      email,
      civilite_id,
      first_name,
      last_name,
      signature_electronique_active,
      errors,
    } = this.state;

    return (
      <TablePopUpContainer
        dialogProps={{
          fullWidth: true,
        }}
        icon={<ManageAccounts />}
        iconColor="primary"
        title="Utilisateurs"
        backendUrl={backendUrl}
        autoSize={false}
        getParams={{ structure_id: structureId }}
        hideEditButton={!canEdit}
        hideDeleteButton={!canDelete}
        colDef={[
          {
            headerName: "#",
            field: "id",
            sort: "desc",
            flex: 1,
          },
          {
            headerName: "Titre",
            field: "titre.value",
            flex: 3,
          },
          {
            headerName: "Civilité",
            field: "user.civilite.value",
            flex: 1,
          },
          {
            headerName: "Nom",
            field: "user.last_name",
            flex: 2,
          },
          {
            headerName: "Prénom",
            field: "user.first_name",
            flex: 2,
          },
          {
            headerName: "Téléphone",
            field: "telephone",
            valueGetter: (params) => Render.telephone(params.data.telephone),
            flex: 2,
          },
          {
            headerName: "Email",
            field: "user.email",
            flex: 3,
          },
        ]}
        formInitData={() => {
          this.setState({
            errors: null,
            id: null,
            titre_id: "",
            telephone: "",
            first_name: "",
            last_name: "",
            email: "",
            civilite_id: "",
            signature_electronique_active: false,
          });
        }}
        formSetData={(data) => {
          const user = data.user;

          this.setState({
            errors: null,
            id: data.id,
            titre_id: data.titre?.id,
            telephone: data.telephone,
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
            civilite_id: user?.civilite_id,
            signature_electronique_active: data.signature_electronique_active,
          });
        }}
        formGetData={() => ({
          id: id,
          titre_id: titre_id,
          telephone: telephone,
          first_name: first_name,
          last_name: last_name,
          email: email,
          civilite_id: civilite_id,
          structure_id: structureId,
          signature_electronique_active: signature_electronique_active,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        formTitle={id ? "Modifier l'utilisateur" : "Ajouter un utilisateur"}
        formConfirmLabel={id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <SelectSearch
                label="Titre"
                name="titre_id"
                apiUrl={configTitreUrl}
                onChange={this.handleChangeByName}
                value={titre_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.titre_id)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SelectSearch
                label="Civilité"
                name="civilite_id"
                apiUrl="/config-user-civilite"
                onChange={this.handleChangeByName}
                value={this.state.civilite_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.civilite_id)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInput
                label="Nom"
                name="last_name"
                value={last_name}
                onChange={this.handleChange}
                error={Boolean(errors?.last_name)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInput
                label="Prénom"
                name="first_name"
                value={first_name}
                onChange={this.handleChange}
                error={Boolean(errors?.first_name)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Email"
                name="email"
                value={email}
                onChange={this.handleChange}
                error={Boolean(errors?.email)}
                helperText={errors?.email?.[0]}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <PhoneInput
                label="Téléphone"
                name="telephone"
                value={telephone}
                onChange={this.handleChangeByName}
                error={Boolean(errors?.telephone)}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ margin: "auto" }}>
              <CheckBoxInput
                label="Signature électronique"
                name="signature_electronique_active"
                value={this.state.signature_electronique_active}
                onChange={this.handleChangeByName}
                disabled={!canToggleEsign}
              />
            </Grid>
          </Grid>
        }
        deleteDialog={{
          title: "Voulez vous supprimer cet utilisateur ?",
          content: (data) => data.value,
          children: null,
          confirmLabel: "Supprimer",
        }}
        failDeleteDialog={{
          title: "Vous ne pouvez pas supprimer cet utilisateur.",
          content: (data) =>
            (data.user?.email ?? "") + " n'a pas été supprimé.",
        }}
      />
    );
  }
}

AgentTable.propTypes = {
  backendUrl: PropTypes.string.isRequired,
  configTitreUrl: PropTypes.string.isRequired,
  structureId: PropTypes.any.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canToggleEsign: PropTypes.bool.isRequired,
};

export default AgentTable;
