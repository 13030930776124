import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import SearchBarService from "services/SearchBarService";
import SearchBar from "components/SearchBar/SearchBar";
import Grid from "@mui/material/Grid";
import Render from "Utils/RenderUtils";
import CopyButtonRenderer from "components/CustomAgRenderer/CopyButtonRenderer";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import GetApp from "@mui/icons-material/GetApp";
import AgGridUtils from "Utils/AgGridUtils";

class PlaceholderDescription extends React.Component {
  constructor(props) {
    super(props);

    this.SEARCH_BAR_SERVICE = new SearchBarService(props.frontUrl);
    this.gridApi = null;
    this.gridColumnApi = null;
    this.getRowStyle = (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return { background: "#f5f5f5" };
      }
    };

    this.state = {
      open: false,
      snackbar: false,
      copiedPlaceholder: "",
      columnDefs: [
        {
          headerName: "Copier",
          field: "",
          // width: 25,
          filter: false,
          editable: false,
          sortable: false,
          cellRenderer: CopyButtonRenderer,
          cellRendererParams: (params) => ({
            valueToCopy: Render.documentPlaceholder(params.data.path),
            onCopy: this.handleCopy,
          }),
        },
        {
          headerName: "Entité",
          field: "entity",
          valueGetter: (params) => Render.modelPath(params.data.entity),
        },
        {
          headerName: "Nom du champ",
          field: "field",
        },
        {
          headerName: "Chemin",
          field: "path",
          valueGetter: (params) => "{{ " + params.data.path + " }}",
        },
        { headerName: "Description", field: "description" },
      ],
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
      },
      data: null,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const params = {
      section: this.props.section,
      type: this.props.type,
    };

    axiosApiBackend
      .get("/documentation/documentPlaceholder", { params })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  handleCopy = (value) => {
    this.setState({ snackbar: true, copiedPlaceholder: value });
  };

  handleOpen = () => {
    this.loadAsyncData();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  render() {
    const {
      open,
      data,
      quickFilterText,
      defaultColDef,
      columnDefs,
      snackbar,
      copiedPlaceholder,
    } = this.state;
    const { color } = this.props;

    return (
      <>
        <Snackbar
          open={snackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity="success">
            Le champ <b>{copiedPlaceholder}</b> a bien été copié
          </Alert>
        </Snackbar>
        <Button color={color} size="small" onClick={this.handleOpen}>
          Voir la liste des champs
        </Button>
        <Dialog open={open} maxWidth="xxl" fullWidth>
          <DialogTitle>Liste et description des champs</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item>
                <SearchBar
                  label="Rechercher"
                  name="quickFilterText"
                  value={quickFilterText}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  resetSearchValue={this.resetSearchValue}
                />
              </Grid>
              <Grid item xs={12} className="ag-theme-material">
                <AgGridReact
                  getRowStyle={this.getRowStyle}
                  overlayNoRowsTemplate="Aucune donnée à afficher."
                  enableCellTextSelection={true}
                  animateRow
                  onGridReady={this.onGridReady}
                  enableFilter={false}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={data}
                  quickFilterText={quickFilterText}
                  onPaginationChanged={this.autoSizeAll}
                  pagination
                  domLayout="autoHeight"
                  paginationPageSize="8"
                  localeText={AG_GRID_LOCALE}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => AgGridUtils.exportCSV(this.gridApi)}
              fullWidth={false}
              startIcon={<GetApp />}
            >
              Télécharger
            </Button>
            <Button onClick={this.handleClose} fullWidth={false} color="onyx">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

PlaceholderDescription.defaultProps = {
  color: "info",
};

PlaceholderDescription.propTypes = {
  section: PropTypes.oneOf(["portail"]).isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  frontUrl: PropTypes.string.isRequired,
};

export default PlaceholderDescription;
