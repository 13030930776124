import React from "react";
import PropTypes from "prop-types";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FormControl from "@mui/material/FormControl";
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

class FilePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, name, margin, onChange, onupdatefiles, ...rest } =
      this.props;

    return (
      <FormControl fullWidth label={label} margin={margin}>
        <FilePond
          onupdatefiles={(fileItems) => {
            onChange(
              name,
              fileItems.map((fileItem) => fileItem.file),
            );
          }}
          {...rest}
        />
      </FormControl>
    );
  }
}

FilePicker.defaultProps = {
  label: null,
  margin: "normal",
  labelIdle: "Ajoutez un document<br>(MAX 10Mo)",
  allowMultiple: false,
  maxTotalFileSize: 10000000,
};

FilePicker.propTypes = {
  label: PropTypes.any,
  name: PropTypes.any,
  margin: PropTypes.any,
  labelIdle: PropTypes.any,
  allowMultiple: PropTypes.any,
  maxTotalFileSize: PropTypes.any,
  files: PropTypes.any,
  onChange: PropTypes.func,
};

export default FilePicker;
