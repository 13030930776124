import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Person from "@mui/icons-material/Person";
import Euro from "@mui/icons-material/EuroSymbol";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import Button from "components/Button/Button";
import FilePicker from "components/FilePicker/FilePicker";
import Divider from "@mui/material/Divider";
import { withUserContext } from "context/UserContext";
import Delete from "@mui/icons-material/Delete";

class EsignatureForm extends React.Component {
  constructor(props) {
    super(props);
    const user = props.user;
    this.BACKEND_URL = "/esignatures";
    this.FRONT_URL = "/esignatures";

    this.state = {
      // NESTED DATA
      signataires: [
        { prenom: user?.firstname, nom: user?.lastname, email: user?.email },
        {},
        // { prenom: "the", nom: "benef", email: "max.destors@gmail.com" },
      ],
      // DATA FORM
      section: "portail",
      title: "",
      files: [],
      // END DATA FORM
      id: props.match.params.id,
      loading: false,
      errors: null,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  changeSignataire = (key, event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const nextSignataires = [...prevState.signataires];
      nextSignataires[key][name] = value;

      return { signataires: nextSignataires };
    });
  };
  addSignataire = () => {
    this.setState((prevState) => {
      const nextSignataires = [...prevState.signataires, {}];

      return { signataires: nextSignataires };
    });
  };
  deleteSignataire = (key) => {
    this.setState((prevState) => {
      const nextSignataires = prevState.signataires.filter((a, k) => k !== key);

      return { signataires: nextSignataires };
    });
  };

  handleSubmit = () => {
    const dataIn = new FormData();

    dataIn.append("section", this.state.section);
    dataIn.append("title", this.state.title);
    this.state.files.forEach((file) => {
      dataIn.append("documents[]", file);
    });
    dataIn.append("signataires", JSON.stringify(this.state.signataires));

    // create
    axiosApiBackend
      .post(this.BACKEND_URL, dataIn, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.handleSuccess(res.data.id);
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          errorMessage: err.response?.data?.error ?? null,
          loading: false,
        });
      });

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleSuccess = (id) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + id);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { signataires, errors, errorMessage } = this.state;

    return (
      <Grid container spacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>{errorMessage ?? "Une erreur est survenue"}</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? <>Esignature</> : <>Esignature</>}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <TextInput
                    label="Titre"
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    error={Boolean(errors?.title)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FilePicker
                    label="Fichier à signer"
                    name="files"
                    allowMultiple
                    labelIdle="Ajoutez des documents<br>(MAX 10Mo)"
                    acceptedFileTypes={[
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "application/pdf",
                    ]}
                    value={this.state.files}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.files)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Signataires
                </Typography>
              }
            />
            <CardContent alignitems="center">
              <Grid container columnSpacing={1}>
                {signataires.map((signataire, key) => (
                  <React.Fragment key={key}>
                    {Boolean(key) && (
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                      </Grid>
                    )}
                    <Grid item xs>
                      <Typography variant="h5">Signataire {key + 1}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="error"
                        size="small"
                        onClick={() => this.deleteSignataire(key)}
                      >
                        <Delete />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Texte d&apos;ancrage : s{key + 1}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Prénom"
                        name="prenom"
                        value={signataire.prenom}
                        onChange={(event) => this.changeSignataire(key, event)}
                        error={Boolean(errors?.[`signataires.${key}.prenom`])}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Nom"
                        name="nom"
                        value={signataire.nom}
                        onChange={(event) => this.changeSignataire(key, event)}
                        error={Boolean(errors?.[`signataires.${key}.nom`])}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Email"
                        name="email"
                        value={signataire.email}
                        onChange={(event) => this.changeSignataire(key, event)}
                        error={Boolean(errors?.[`signataires.${key}.email`])}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12} textAlign="right">
                  <Button
                    color="success"
                    size="small"
                    onClick={this.addSignataire}
                  >
                    Ajouter un signataire
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

EsignatureForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.any,
};

export default withUserContext(EsignatureForm);
